
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.transcript-captions-wrapper::v-deep {
    display: flex;
    flex-direction: column;

    .v-input {
        width: 80%;
        margin-bottom: 1em;

        &:not(.host) {
            align-self: end;
            background-color: rgba($secondary-color, 0.1);
        }

        .v-text-field__slot {
            label {
                visibility: hidden;
            }

            textarea {
                margin-top: 0;
            }
        }
    }
}

.topic-input::v-deep {
    margin-bottom: 1em;

    .v-text-field__slot {
        label {
            visibility: hidden;
        }

        textarea {
            margin-top: 0;
        }
    }
}
